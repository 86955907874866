
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Button, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { categoriaListaStart } from "../../../actions/categoria";
import { productoPorID, productosDeleteActivo, productosUpdateStart } from "../../../actions/producto";
import { MostrarImagenProducto } from "../../components";
import { LayoutReturn } from "../../layouts";
import { NumberFormatCustom } from "../../../hooks";
import Swal from "sweetalert2";



const rolesPermitidos = ['SUPERADMIN', 'ADMIN'];
const initProducto = {
    nombre: "",
    codigo: "",
    costoMateria: "",
    costoProduccion: "",
    costoTotal: "",
    precioMinimoVenta: "",
    precioVenta: "",
    precioDistri: "",
    precioPublicista: "",
    precioEmpresa: "",
    categoria: "",
    recomendaciones: "",
    caracteristicas: "",
    uso: "",
    tipo: ""
};




export const ActualizarProducto = () => {


    const { id } = useParams();
    const dispatch = useDispatch();
    const { usuario } = useSelector( state => state.admin );
    const { categorias } = useSelector( state => state.categoria );
    const  { activeProducto } = useSelector( state => state.producto );
    const millares = NumberFormatCustom;


    useEffect(() => {
        dispatch( categoriaListaStart() );
    }, [dispatch])


    useEffect( () => {
        if( id ) {
            dispatch( productoPorID( id ) );
        }
    }, [id] );



    useEffect( () => {
        if( activeProducto ){
            setFormProducto({ 
                ...activeProducto,
                tipo: activeProducto.tipo || '',
                categoria: activeProducto?.categoria?._id
            });
        }
    }, [ activeProducto ] );



    const [formProducto, setFormProducto ] = useState( initProducto );


    const { 
        nombre,
        codigo,
        costoMateria,
        costoProduccion,
        precioVenta,
        precioDistri,
        precioPublicista,
        precioEmpresa,
        recomendaciones,
        caracteristicas,
        uso,
        tipo,
        categoria,    
        costoTotal,
        precioMinimoVenta,
    } = formProducto;


    const handleInputChange = ({ target }) => {
        setFormProducto({
            ...formProducto,
            [target.name]: target.value
        });
    }

    const handleActualizarProducto = (e) => {
        e.preventDefault();
    
        Swal.fire({
            title: 'Actualizar Producto?',
            text: `Esta Seguro De Actualizar A ${ nombre }`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si, Actualizar' 
        }).then( ( result ) => {
            if(result.value){
                dispatch( productosUpdateStart( formProducto ) );
                dispatch( productosDeleteActivo() ); 
                // navigate('/super/listaProducto');
            }
        })
    }


    return (

        <LayoutReturn titulo='Actualizar Producto' url={-1}>

            <MostrarImagenProducto tipo='productos' data={activeProducto} />

            <form className="formulario-total mt-5" onSubmit={ handleActualizarProducto } >
            <Grid container spacing={ 4 }>

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        label='Nombre'
                        type='text'
                        placeholder='Nombre'
                        fullWidth
                        name="nombre"
                        value={ nombre }
                        onChange={ handleInputChange }
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        label='Codigo'
                        type='text'
                        placeholder='Codigo'
                        fullWidth
                        name="codigo"
                        value={ codigo }
                        onChange={ handleInputChange }
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        label='Recomendaciones'
                        type='text'
                        placeholder='Recomendaciones'
                        fullWidth
                        name="recomendaciones"
                        value={ recomendaciones }
                        onChange={ handleInputChange }
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        label='Caracteristicas'
                        type='text'
                        placeholder='Caracteristicas'
                        fullWidth
                        name="caracteristicas"
                        value={ caracteristicas }
                        onChange={ handleInputChange }
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        label='Uso'
                        type='text'
                        placeholder='Uso'
                        fullWidth
                        name="uso"
                        value={ uso }
                        onChange={ handleInputChange }
                    />
                </Grid>

                <Grid item xs={ 12 } sm={ 6 } md={4} mt={ 2 } >
                    <TextField
                        variant="filled"
                        select
                        label="Seleccione Tipo"
                        fullWidth
                        name='tipo'
                        value={ tipo }
                        onChange={ handleInputChange }
                    >
                        <MenuItem value="">Seleccione Un Tipo</MenuItem>
                        <MenuItem value="TARJETAS-VOLANTES-MEMBRETES">TARJETAS-VOLANTES-MEMBRETES</MenuItem>
                        <MenuItem value="CARPETAS-PLEGABLES">CARPETAS-PLEGABLES</MenuItem>
                    </TextField>
                </Grid>

                <Grid item xs={ 12 } sm={ 6 } md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        select
                        label="Seleccione Categoria"
                        fullWidth
                        name='categoria'
                        value={ categoria }
                        onChange={ handleInputChange }
                    >
                        <MenuItem value=''>
                            Seleccione Categoria
                        </MenuItem>
                        {
                        categorias.map( g => (
                        <MenuItem key={ g.id } value={ g.id } >
                            { g.nombre }
                        </MenuItem>
                        ))
                        }
                    </TextField>
                </Grid>

                { ( rolesPermitidos.includes(usuario?.role) ) &&
                <>
                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        label='Costo Materia'
                        placeholder='Costo Materia'
                        fullWidth
                        name="costoMateria"
                        value={ costoMateria }
                        onChange={ handleInputChange }
                        InputProps={{
                            inputComponent: millares,
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        label='Costo Produccion'
                        placeholder='Costo Produccion'
                        fullWidth
                        name="costoProduccion"
                        value={ costoProduccion }
                        onChange={ handleInputChange } 
                        InputProps={{
                            inputComponent: millares,
                        }}
                    />
                </Grid>


                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        label='Costo Total'
                        placeholder='Costo Total'
                        fullWidth
                        name="costoTotal"
                        value={ costoTotal }
                        onChange={ handleInputChange } 
                        InputProps={{
                            inputComponent: millares,
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField 
                        variant="filled"
                        label='Precio Minimo Venta'
                        placeholder='Precio Minimo Venta'
                        fullWidth
                        name="precioMinimoVenta"
                        value={ precioMinimoVenta }
                        onChange={ handleInputChange } 
                        InputProps={{
                            inputComponent: millares,
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        label='Precio Venta'
                        placeholder='Precio Venta'
                        fullWidth
                        name="precioVenta"
                        value={ precioVenta }
                        onChange={ handleInputChange } 
                        InputProps={{
                            inputComponent: millares,
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        label='Precio Distri'
                        placeholder='Precio Distri'
                        fullWidth
                        name="precioDistri"
                        value={ precioDistri }
                        onChange={ handleInputChange } 
                        InputProps={{
                            inputComponent: millares,
                        }}
                    />
                </Grid>  

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        label='Precio Publicista'
                        placeholder='Precio Publicista'
                        fullWidth
                        name="precioPublicista"
                        value={ precioPublicista }
                        onChange={ handleInputChange }
                        InputProps={{
                            inputComponent: millares,
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        label='Precio Empresa'
                        placeholder='Precio Empresa'
                        fullWidth
                        name="precioEmpresa"
                        value={ precioEmpresa }
                        onChange={ handleInputChange }
                        InputProps={{
                            inputComponent: millares,
                        }}
                    />
                </Grid>
                </>
                }

            </Grid>

                <Grid container  my={7} direccion='column' justifyContent='center'>
                    <Grid item xs={ 12 } sm={ 7 } >
                        <Button variant="contained" color="secondary" fullWidth type="submit" >
                            <Typography variant="span" color='white' >Actualizar Producto</Typography>
                        </Button>
                    </Grid>
                </Grid>

            </form>
        </LayoutReturn>
    )
}
