
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Button, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { LayoutReturn } from "../../layouts";
import { usuarioDeleteActivo, usuarioPorID, usuarioUpdateStart } from "../../../actions/usuario";
import { MostrarImagen } from "../../ui/MostrarImagen";
import { roles_permitidos } from "../../../data";

import Swal from "sweetalert2";




const initUser = {
    nombre: "",
    cedula: "",
    ciudad: "",
    direccion: "",
    movil: "",
    establecimiento: "",
    profesion: "",
    role: "",
    email: "",
};




export const ActualizarUsuario = () => {

    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { activeUser } = useSelector(state => state.user);


    const [formUsuario, setFormUsuario ] = useState( initUser );
    const { nombre, cedula, ciudad, direccion, movil, role, establecimiento,
    profesion, email } = formUsuario;
    const handleInputChange = ({ target }) => {
        setFormUsuario({
        ...formUsuario,
        [target.name]: target.value
        });
    }


    useEffect( () => {
        if( id ) { dispatch(usuarioPorID(id)) }
    }, [id] );



    useEffect( () => {
        if( activeUser ){
            setFormUsuario({ ...activeUser, email: activeUser.email || '' });
        }
    }, [activeUser]);




    // Creacion del Usuario
    const actualizarUsuario = (e) => {
        e.preventDefault();

        if([nombre, email, role, direccion, movil].includes('') ) {
            return Swal.fire('Error', 'El Hay Datos Obligatorios', 'error');
        }

        Swal.fire({
            title: 'Actualizar Usuario',
            text: `¿Esta Seguro De Actualizar A ${ nombre }?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si, Actualizar' 
        }).then( ( result ) => {
            if(result.value){
                dispatch(usuarioUpdateStart( formUsuario ));
                dispatch(usuarioDeleteActivo()); 
                navigate( -1 );
            }
        })
    }


    return (


        <LayoutReturn titulo={'Actualizar Usuario'} url={-1}>

            <MostrarImagen />

            <form className="formulario-total mt-5" onSubmit={ actualizarUsuario } >
            <Grid container spacing={ 4 }>

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        label='Nombre Usuario'
                        type='text'
                        placeholder='Nombre Usuario'
                        fullWidth
                        name="nombre"
                        value={ nombre }
                        onChange={ handleInputChange }
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        label='Cedula Usuario'
                        type='text'
                        placeholder='Cedula Usuario'
                        fullWidth
                        name="cedula"
                        value={ cedula }
                        onChange={ handleInputChange }
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        label='Correo Electronico'
                        type='text'
                        placeholder='Correo Electronico'
                        fullWidth
                        name="email"
                        value={ email }
                        onChange={ handleInputChange }
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        label='Movil'
                        type='text'
                        placeholder='Movil'
                        fullWidth
                        name="movil"
                        value={ movil }
                        onChange={ handleInputChange }
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField 
                        variant="filled"
                        label='Establecimiento'
                        type='text'
                        placeholder='Establecimiento'
                        fullWidth
                        name="establecimiento"
                        value={ establecimiento }
                        onChange={ handleInputChange } 
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField 
                        variant="filled"
                        label='Profesión'
                        type='text'
                        placeholder='Profesión'
                        fullWidth
                        name="profesion"
                        value={ profesion }
                        onChange={ handleInputChange } 
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField 
                        variant="filled"
                        label='Ciudad'
                        type='text'
                        placeholder='Ciudad'
                        fullWidth
                        name="ciudad"
                        value={ ciudad }
                        onChange={ handleInputChange } 
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        label='Dirección'
                        type='text'
                        placeholder='Dirección'
                        fullWidth
                        name="direccion"
                        value={ direccion }
                        onChange={ handleInputChange }
                    />
                </Grid>

                <Grid item xs={ 12 } sm={ 6 } md={4} mt={ 2 } >
                    <TextField 
                        required
                        variant="filled"
                        select
                        label="Seleccione Rol"
                        fullWidth
                        name='role'
                        value={ role }
                        onChange={ handleInputChange }
                    >
                        <MenuItem value="">Seleccione Un Rol</MenuItem>
                        {roles_permitidos.map( item => (
                            <MenuItem value={item}>{item}</MenuItem>
                        ))}
                    </TextField>
                </Grid>

            </Grid>

                <Grid container  my={7} direccion='column' justifyContent='center'>
                    <Grid item xs={ 12 } sm={ 7 } >
                        <Button variant="contained" color="secondary" fullWidth type="submit" >
                            <Typography variant="span" color='white' >Actualizar Usuario</Typography>
                        </Button>
                    </Grid>
                </Grid>

            </form>
        </LayoutReturn>


    )


    
}
