
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { LayoutReturn } from "../../layouts";
import { categoriaDeleteActivo, categoriaPorID, categoriaUpdateStart } from "../../../actions/categoria";
import { MostrarImagenProducto } from "../../components";

import Swal from "sweetalert2";



const initCategoria = {
    nombre: "",
    tags: "",
    primero: 0,
    segundo: 0,
    tercero: 0,
    cuarto: 0,
    quinto: 0,
    sexto: 0,
    septimo: 0,
};



export const ActualizarCategoria = () => {


    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const  { activeCategoria } = useSelector( state => state.categoria );

    useEffect( () => {
        if( activeCategoria ){
            setFormCategoria( activeCategoria );
        }
    }, [ activeCategoria ] );



    useEffect( () => {
        if( id ) { dispatch( categoriaPorID( id ) ); }
    }, [id] );


    const [ formCategoria, setFormCategoria ] = useState( initCategoria );
    const { 
        nombre,
        tags,
        primero,
        segundo,
        tercero,
        cuarto,
        quinto,
        sexto,
        septimo,   
    } = formCategoria;


    const handleInputChange = ({ target }) => {
        setFormCategoria({
            ...formCategoria,
            [target.name]: target.value
        });
    }



    const handleActualizarCategoria = (e) => {
        e.preventDefault();

        Swal.fire({
            title: 'Actualizar Producto?',
            text: `Esta Seguro De Actualizar A ${ nombre }`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si, Actualizar' 
        }).then( ( result ) => {
            if(result.value){
                dispatch( categoriaUpdateStart( formCategoria ) );
                dispatch( categoriaDeleteActivo() ); 
                navigate('/super/lista-categoria');
            }
        })
    }

    return (


        <LayoutReturn titulo='Actualizar Categoria' url='/super/lista-categoria'>

            <MostrarImagenProducto tipo='categorias' data={activeCategoria} />

            <form className="formulario-total mt-5" onSubmit={ handleActualizarCategoria } >
            <Grid container spacing={ 4 }>

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        label='Nombre'
                        type='text'
                        placeholder='Nombre'
                        fullWidth
                        name="nombre"
                        value={ nombre }
                        onChange={ handleInputChange }
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        label='Primero'
                        type='number'
                        placeholder='Primero'
                        fullWidth
                        name="primero"
                        value={ primero }
                        onChange={ handleInputChange } 
                    />
                </Grid>


                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        label='Segundo'
                        type='number'
                        placeholder='Segundo'
                        fullWidth
                        name="segundo"
                        value={ segundo }
                        onChange={ handleInputChange } 
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        label='Tercero'
                        type='number'
                        placeholder='Tercero'
                        fullWidth
                        name="tercero"
                        value={ tercero }
                        onChange={ handleInputChange } 
                    />
                </Grid>


                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        label='Cuarto'
                        type='number'
                        placeholder='Cuarto'
                        fullWidth
                        name="cuarto"
                        value={ cuarto }
                        onChange={ handleInputChange } 
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        label='Quinto'
                        type='number'
                        placeholder='Quinto'
                        fullWidth
                        name="quinto"
                        value={ quinto }
                        onChange={ handleInputChange } 
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        label='Sexto'
                        type='number'
                        placeholder='Sexto'
                        fullWidth
                        name="sexto"
                        value={ sexto }
                        onChange={ handleInputChange }
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        label='Septimo'
                        type='number'
                        placeholder='Septimo'
                        fullWidth
                        name="septimo"
                        value={ septimo }
                        onChange={ handleInputChange } 
                    />
                </Grid>

                <Grid item xs={12} sm={8} md={6} mt={ 2 } >
                    <TextField required
                        variant="filled"
                        label='Tags (Separar con Coma)'
                        multiline
                        type='number'
                        placeholder='Tags (Separar con Coma)'
                        fullWidth
                        name="tags"
                        value={ tags }
                        onChange={ handleInputChange } 
                    />
                </Grid>

            </Grid>

                <Grid container  my={7} direccion='column' justifyContent='center'>
                    <Grid item xs={ 12 } sm={ 7 } >
                        <Button variant="contained" color="secondary" fullWidth type="submit" >
                            <Typography variant="span" color='white' >Actualizar Categoria</Typography>
                        </Button>
                    </Grid>
                </Grid>
            </form>

        </LayoutReturn>
    )
}
