
import { useDispatch } from 'react-redux';
import { startUploadingCategoria, startUploadingProductos } from '../../../actions/uploads';
import { Button, Card, CardMedia, Grid } from '@mui/material';


const baseUrl = process.env.REACT_APP_API_URL;



export const MostrarImagenProducto = ({ tipo, data }) => {
    
    
    const dispatch = useDispatch();


    const handlePictureClick = () => {
        document.querySelector('#fileSelector').click();
    }


    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if( file ) {
            if(tipo === 'productos'){
                dispatch(startUploadingProductos( data.id, tipo, file ));
            } else {
                dispatch(startUploadingCategoria( data.id, tipo, file  ));
            }
        }
    }


    
    return (

        <Grid container sx={{display:'flex', flexDirection:'column', alignItems:'center'}}>
            <Card sx={{mt:4, mb:2}}>
                <CardMedia  sx={{width:400, height:300, objectFit:'cover'}}>
                    <img src={`${baseUrl}/uploads/${tipo}/${data?.img || 'gasdgsahgf'}`} 
                        alt={`Imagen de ${tipo}`} style={{ width: '100%', height: '100%' }}
                    />
                </CardMedia>
            </Card>

            <Button color="primary" onClick={ handlePictureClick } sx={{bgcolor:'primary.main' ,mb:4, px:6}}>
                Subir Imagen
            </Button>
            <input type="file" id="fileSelector" className="boton-file"
                onChange={ handleFileChange } name="file" style={{ display: 'none' }}
            />
        </Grid>
    )
}
    

